import React from "react";

const Features2 = () => (
  <div className="features-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="features-wrap">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item communities bg-image-white">
                  <div className="features-space" />

                  <div className="features-content">
                    <h2>
                      9 <br /> COMUNIDADES <br /> MOÇAMBIQUE
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item displaced-families bg-image-yellow">
                  <div className="features-space" />
                  <div className="features-content">
                    <h2>
                      149 <br /> FAMÍLIAS DESLOCADAS DE <br />
                      CABO DELGADO APOIADAS
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item tree-fruit bg-image-white">
                  <div className="features-space" />
                  <div className="features-content">
                    <h2>
                      313 <br /> ÁRVORES DE FRUTO <br />
                      PLANTADAS
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item educational-incentives bg-image-yellow">
                  <div className="features-space" />
                  <div className="features-content">
                    <h2>
                      +3569 <br /> INCENTIVOS <br />
                      EDUCACIONAIS
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item library bg-image-white">
                  <div className="features-space" />
                  <div className="features-content">
                    <h2>
                      1 <br />
                      BIBLIOTECA
                      <br /> COMUNITÁRIA
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="features-item parteners bg-image-yellow">
                  <div className="features-space" />
                  <div className="features-content">
                    <h2>
                      6 <br /> PARCEIROS <br />
                      ATIVOS
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Features2;
