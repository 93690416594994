import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Donate from "../components/Donate";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

const DonatePage = () => (
  <>
    <Navbar />
    <PageTitle backgroundClassName={"donate"} title={"Faça um donativo"} />
    <Donate />
    <Footer />
    <Scrollbar />
  </>
);
export default DonatePage;
