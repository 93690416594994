import React from "react";
import { Link } from "react-router-dom";
import Image from "react-image-webp";
import "./Projects.css";
import thumbProjectSustentabilidadeAlimentarImg from "../../assets/images/projects-thumbnail-sustentabilidade-alimentar.jpg";
import thumbProjectSustentabilidadeAlimentarImgWebP from "../../assets/images/webp/projects-thumbnail-sustentabilidade-alimentar.webp";
import thumbProjectApoioEducacionalImg from "../../assets/images/projects-thumbnail-apoio-educacional.jpg";
import thumbProjectApoioEducacionalImgWebP from "../../assets/images/webp/projects-thumbnail-apoio-educacional.webp";

const ProjectsSection = (props) => (
  <div className={`volunteer-area section-padding ${props.addclass}`}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div className="section-title section-title2 text-center">
            <p>
              Acreditamos que a cooperação é a chave para uma mudança efectiva.
              Trabalhamos em conjunto com Associações Lociais ou Líderes
              Servidores que queiram mudar o Mundo!
            </p>
          </div>
        </div>
      </div>

      <div className="volunteer-wrap">
        <div className="row align-center">
          <div className="col col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <Link to="/projetos/sustentabilidade-alimentar">
                <div className="volunteer-img">
                  <Image
                    src={thumbProjectSustentabilidadeAlimentarImg}
                    webp={thumbProjectSustentabilidadeAlimentarImgWebP}
                    alt="Programa de Sustentabilidade Alimentar"
                    draggable="false"
                  />
                </div>
                <div className="volunteer-content">
                  <h2>Programa de Sustentabilidade Alimentar</h2>
                </div>
              </Link>
            </div>
          </div>
          <div className="col col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <Link to="/projetos/apoio-educacional">
                <div className="volunteer-img">
                  <Image
                    src={thumbProjectApoioEducacionalImg}
                    webp={thumbProjectApoioEducacionalImgWebP}
                    alt="Projeto de Apoio Educacional"
                    draggable="false"
                  />
                </div>
                <div className="volunteer-content">
                  <h2>Projeto de Apoio Educacional</h2>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProjectsSection;
