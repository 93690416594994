import classNames from "classnames";
import React from "react";
import "./style.css";

const PageTitle = ({ backgroundClassName = "", title = "", subtitle = "" }) => {
  const pageTitleClass = classNames(`page-title`, {
    [`contacts`]: backgroundClassName === "contacts",
    [`partners`]: backgroundClassName === "partners",
    [`donate`]: backgroundClassName === "donate",
    [`solitary`]: backgroundClassName === "solitary",
    [`about`]: backgroundClassName === "about",
    [`projects`]: backgroundClassName === "projects",
    [`project-sustentabilidade-alimentar`]:
      backgroundClassName === "project-sustentabilidade-alimentar",
    [`project-apoio-educacional`]:
      backgroundClassName === "project-apoio-educacional",
    [`error-page`]:
      backgroundClassName === "error-page",
      
  });
  return (
    <section className={pageTitleClass}>
      <div className="page-title-container">
        <div className="page-title-wrapper">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>{title}</h2>
                {!!subtitle && (
                  <ol className="breadcrumb">
                    <li>{subtitle}</li>
                  </ol>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
