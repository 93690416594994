import React from "react";
import Image from "react-image-webp";
import "./style.css";

const Articles = ({ articlesList = [], onArticleSelected = () => {} }) => {
  const removeAllSelectedArticleClass = () => {
    const allChildElementsWithClass = document.querySelectorAll(
      ".article-selected"
    );
    allChildElementsWithClass.forEach((element) => {
      element.classList.remove("article-selected");
    });
  };

  return (
    <div className="articles-area section-padding">
      <div className="container">
        <div className="row">
          {articlesList &&
            articlesList.map((article, index) => (
              <div
                className="col-lg-4 col-md-6 col-12 text-center"
                key={`article-${index}`}
              >
                <button
                  onClick={(e) => {
                    // Remove all active class
                    removeAllSelectedArticleClass();

                    e.currentTarget.classList.add("article-selected");

                    onArticleSelected(article.title);
                  }}
                >
                  <div className="cause-item">
                    <div className="cause-top">
                      <div className="cause-img">
                        <Image
                          src={require(`../../assets/images/${article.image}`)}
                          webp={require(`../../assets/images/webp/${article.imagewebp}`)}
                          alt={article.title}
                          draggable="false"
                        />
                      </div>
                    </div>
                    <div className="cause-text">
                      <ul>
                        <li>
                          <div className="price">{article.value}</div>
                        </li>
                      </ul>
                      <h3>{article.title}</h3>
                    </div>
                  </div>
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Articles;
