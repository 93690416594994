import React from "react";
import Image from "react-image-webp";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import ProjectsPhotos from "../components/ProjectsPhotos";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import kitsEscolaresImg from "../assets/images/projects-proj-apoio-educacional-kits-escolares.jpg";
import kitsEscolaresImgWebP from "../assets/images/webp/projects-proj-apoio-educacional-kits-escolares.webp";
import uniformesEscolaresImg from "../assets/images/projects-proj-apoio-educacional-uniformes-escolares.jpg";
import uniformesEscolaresImgWebP from "../assets/images/webp/projects-proj-apoio-educacional-uniformes-escolares.webp";
import pensosHigienicosReutilizaveisImg from "../assets/images/projects-proj-apoio-educacional-pensos-higienicos-reutilizaveis.jpg";
import pensosHigienicosReutilizaveisImgWebP from "../assets/images/webp/projects-proj-apoio-educacional-pensos-higienicos-reutilizaveis.webp";

const photosList = [
  {
    image:
      "projects-proj-apoio-educacional-ee4221ea-10a5-4af2-b212-2531e67d7ee6.jpg",
    imageWebP:
      "projects-proj-apoio-educacional-ee4221ea-10a5-4af2-b212-2531e67d7ee6.webp",
    title: "",
  },
  {
    image:
      "projects-proj-apoio-educacional-f133e942-9d89-4c16-b810-f986d9a12094.jpg",
    imageWebP:
      "projects-proj-apoio-educacional-f133e942-9d89-4c16-b810-f986d9a12094.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20210406-WA0037.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20210406-WA0037.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20210619-WA0016.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20210619-WA0016.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20210702-WA0017.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20210702-WA0017.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20221015-WA0044.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20221015-WA0044.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20221027-WA0058.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20221027-WA0058.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20221027-WA0063.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20221027-WA0063.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20221027-WA0064.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20221027-WA0064.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20230210-WA0051.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20230210-WA0051.webp",
    title: "",
  },
  {
    image: "projects-proj-apoio-educacional-IMG-20230210-WA0083.jpg",
    imageWebP: "projects-proj-apoio-educacional-IMG-20230210-WA0083.webp",
    title: "",
  },
];

const ProjectApoioEducacionalPage = () => (
  <>
    <Navbar />
    <PageTitle
      backgroundClassName="project-apoio-educacional"
      title={"Projeto de Apoio Educacional"}
    />
    <div className="projects">
      <section className="project-container orange">
        <div className="row">
          <div className="col">
            <div id="Description">
              <div className="case-b-text row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="project-cause">
                    <div className="cause-img">
                      <Image
                        src={uniformesEscolaresImg}
                        webp={uniformesEscolaresImgWebP}
                        alt="Apoiamos crianças e jovens em situação de grande vulnerabilidade"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0">
                  <p>
                    Apoiamos crianças e jovens em situação de grande
                    vulnerabilidade, em que as suas famílias não têm condições
                    financeiras para a compra de material escolar, uniformes ou
                    de pensos higiénicos femininos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="project-container">
        <div className="row">
          <div className="col">
            <div id="Description">
              <div className="case-b-text row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="project-cause">
                    <div className="cause-img">
                      <Image
                        src={pensosHigienicosReutilizaveisImg}
                        webp={pensosHigienicosReutilizaveisImgWebP}
                        alt="Raparigas sem acesso a pensos higiénicos"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0">
                  <p>
                    As raparigas sem acesso a pensos higiénicos ficam na época
                    menstrual fora da escola, atrasando-lhes assim a sua
                    aprendizagem e ficando em desvantagem face aos rapazes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="project-container orange">
        <div className="row">
          <div className="col">
            <div id="Description">
              <div className="case-b-text row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="project-cause">
                    <div className="cause-img">
                      <Image
                        src={kitsEscolaresImg}
                        webp={kitsEscolaresImgWebP}
                        alt="Kits Escolares"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0">
                  <p>
                    Apoiamos Bibliotecas Comunitárias com livros. Temos o
                    cuidadoso trabalho de conferir se cada livro se esquadra com
                    a cultura moçambicana e que, de outra forma não teriam
                    acesso, sendo os mesmos vindos do estrangeiro.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container section-padding">
        <div className="row">
          <div className="col col-12">
            <div className="tp-case-content">
              <div className="tp-case-details-wrap">
                <div className="tp-case-details-text">
                  <div className="case-bb-text">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                          <div className="cause-top">
                            <div className="cause-img">
                              <Image
                                src={kitsEscolaresImg}
                                webp={kitsEscolaresImgWebP}
                                alt="Kits Escolares"
                                draggable="false"
                              />
                            </div>
                          </div>
                          <div className="cause-text">
                            <h3>
                              1500 Kits
                              <br />
                              escolares
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                          <div className="cause-top">
                            <div className="cause-img">
                              <Image
                                src={uniformesEscolaresImg}
                                webp={uniformesEscolaresImgWebP}
                                alt="Uniformes Escolares"
                                draggable="false"
                              />
                            </div>
                          </div>
                          <div className="cause-text">
                            <h3>
                              249 Uniformes
                              <br />
                              escolares
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                          <div className="cause-top">
                            <div className="cause-img">
                              <Image
                                src={pensosHigienicosReutilizaveisImg}
                                webp={pensosHigienicosReutilizaveisImgWebP}
                                alt="Pensos higiénicos reutilizáveis"
                                draggable="false"
                              />
                            </div>
                          </div>
                          <div className="cause-text">
                            <h3>
                              400 Pensos
                              <br />
                              higiénicos reutilizáveis
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProjectsPhotos photosList={photosList} />
    </div>
    <Footer />
    <Scrollbar />
  </>
);
export default ProjectApoioEducacionalPage;
