import React, { useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import * as emailjs from "emailjs-com";

import Articles from "../Articles";
import "./style.css";

const articles = [
  {
    image: "solidarity-articles-lembrancas-solidarias.jpg",
    imagewebp: "solidarity-articles-lembrancas-solidarias.webp",
    title: "Lembranças Solidárias",
    value: "1,50 €",
  },
  {
    image: "solidarity-articles-estatuetas.jpg",
    imagewebp: "solidarity-articles-estatuetas.webp",
    title: "Estatuetas",
    value: "4,00 €",
  },
  {
    image:
      "solidarity-articles-6832ea_a8d69b123abc435482e222e1ef6f8f37_mv2.jpg",
    imagewebp:
      "solidarity-articles-6832ea_a8d69b123abc435482e222e1ef6f8f37_mv2.webp",
    title: "Capulana com bainha",
    value: "12,00 €",
  },
];

const SolidatiryArticles = () => {
  const formRef = useRef();

  const [mailInfo, setMailInfo] = useState({
    mailSend: false,
    mailError: false,
  });

  const [articleData, setArticleData] = useState({
    name: "",
    email: "",
    product: "",
    errors: {
      name: "",
      email: "",
      product: "",
    },
  });

  const validateForm = () => {
    let errors = {};
    let formatIsValid = true;

    const { name, email, product } = articleData;

    if (product.trim() === "") {
      errors.product = "Necessia selecionar um produto";
      formatIsValid = false;
    }

    if (name.trim() === "" || name.trim().length < 3) {
      errors.name = "Digite o seu nome";
      formatIsValid = false;
    }

    if (email.trim() === "" || email.trim().length < 3) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!pattern.test(email)) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    // if (message.trim() !== "" && message.trim().length < 10) {
    //   errors.message = "Deixa uma mensagem mais extensa";
    //   formatIsValid = false;
    // }

    setArticleData({
      ...articleData,
      errors,
    });

    return formatIsValid;
  };

  const submitArticleRequest = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    console.log("articleData", articleData);

    const { name, email, product } = articleData;

    var templateParams = {
      from_email: email,
      from_name: name,
      reply_to: email,
      from_subject: "Aquisição de artigo solidário",
      message_html: product,
      to_name: "Associação IMANI",
    };

    emailjs
      .send(
        "gmail-service-rifsce8",
        "template_sit25mb",
        templateParams,
        "r-QdtkroXmlrIyR9w"
      )
      .then(
        (response) => {
          setMailInfo({ mailSend: true, mailError: false });

          // Clear form
          setArticleData({
            name: "",
            email: "",
            product: "",
            errors: {
              name: "",
              email: "",
              product: "",
            },
          });

          // Remove selected product
          const allChildElementsWithClass = document.querySelectorAll(
            ".article-selected"
          );
          allChildElementsWithClass.forEach((element) => {
            element.classList.remove("article-selected");
          });
        },
        (err) => {
          setMailInfo({ mailSend: false, mailError: true });
        }
      );
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setArticleData({
      ...articleData,
      [name]: value,
      errors: {
        ...articleData.errors,
        [name]: "",
      },
    });
  };

  const onArtcileSelected = (product) => {
    setArticleData({
      ...articleData,
      product,
      errors: {
        ...articleData.errors,
        product: "",
      },
    });

    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const successMessage = () => {
    const { mailSend, mailError } = mailInfo;

    if (mailSend) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-success"
              dangerouslySetInnerHTML={{
                __html:
                  "<b>Enviado!</b><br/>Recebemos a tua mensagem, prometemos ser breves na resposta.",
              }}
            />
          </div>
        </div>
      );
    }

    if (mailError) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{
                __html:
                  "<b>Erro!</b><br/>Ocurreu um erro ao enviar o email. Tente novamente!",
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="volunteer-page">
      <Articles articlesList={articles} onArticleSelected={onArtcileSelected} />

      <div className="volunteer-area section-padding-bottom">
        <div className="volunteer-wrap">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="volunteer-contact">
                  <div className="volunteer-contact-form">
                    <form
                      ref={formRef}
                      className="contact-validation-active"
                      id="solidatiry-article-form"
                    >
                      <div className="row">
                        <div className="col-12">
                          <h1>Tenho interesse no artigo</h1>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Nome"
                            required
                            value={articleData.name}
                            onChange={handleInputChange}
                          />
                          {articleData.errors.name && (
                            <Alert variant={"danger"}>
                              {articleData.errors.name}
                            </Alert>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                            required
                            value={articleData.email}
                            onChange={handleInputChange}
                          />
                          {articleData.errors.email && (
                            <Alert variant={"danger"}>
                              {articleData.errors.email}
                            </Alert>
                          )}
                        </div>
                        {/* <div className="col-lg-12 col-12 form-group">
                          <textarea
                            className="form-control"
                            name="note"
                            id="note"
                            placeholder="Mensagem"
                            onChange={handleInputChange}
                          />
                          {articleData.errors.message && (
                            <Alert variant={"danger"}>
                              {articleData.errors.message}
                            </Alert>
                          )}
                        </div> */}
                        <div className="submit-area col-lg-12 col-12">
                          {articleData.errors.product && (
                            <Alert variant={"danger"}>
                              {articleData.errors.product}
                            </Alert>
                          )}
                          <button
                            type="submit"
                            className="theme-btn submit-btn"
                            onClick={submitArticleRequest}
                          >
                            Submeter pedido
                          </button>
                        </div>
                      </div>
                      {successMessage()}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolidatiryArticles;
