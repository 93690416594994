import React from "react";
import Image from "react-image-webp";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import ProjectsPhotos from "../components/ProjectsPhotos";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import projPlantacaoFrutoImg from "../assets/images/projects-proj-plantacao-fruto.jpg";
import projPlantacaoFrutoImgWebP from "../assets/images/webp/projects-proj-plantacao-fruto.webp";
import projApoioDeslocadosCaboDelgadoImg from "../assets/images/projects-proj-apoio-deslocados-cabo-delgado.jpeg";
import projApoioDeslocadosCaboDelgadoImgWebP from "../assets/images/webp/projects-proj-apoio-deslocados-cabo-delgado.webp";

const photosList = [
  {
    image: "projects-proj-sustentabilidade-alimentar-01.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-01.webp",
    title: "",
  },
  {
    image: "projects-proj-sustentabilidade-alimentar-02.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-02.webp",
    title: "",
  },
  {
    image: "projects-proj-sustentabilidade-alimentar-03.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-03.webp",
    title: "",
  },
  {
    image: "projects-proj-sustentabilidade-alimentar-04.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-04.webp",
    title: "",
  },
  {
    image: "projects-proj-sustentabilidade-alimentar-05.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-05.webp",
    title: "",
  },
  {
    image: "projects-proj-sustentabilidade-alimentar-06.jpg",
    imageWebP: "projects-proj-sustentabilidade-alimentar-06.webp",
    title: "",
  },
];

const ProjectSustentabilidadeAlimentarPage = () => (
  <>
    <Navbar />
    <PageTitle
      backgroundClassName="project-sustentabilidade-alimentar"
      title={"Sustentabilidade Alimentar"}
    />

    <div className="project-description">
      <section className="project-container orange">
        <div className="row">
          <div className="col">
            <div id="Description">
              <div className="case-b-text row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="project-cause">
                    <div className="cause-img">
                      <Image
                        src={projPlantacaoFrutoImg}
                        webp={projPlantacaoFrutoImgWebP}
                        alt="Projeto de Plantação de Árvores de Fruto em Comunidades Vulneráveis"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0">
                  <h3>
                    Projeto de Plantação de Árvores de Fruto em Comunidades
                    Vulneráveis
                  </h3>
                  <p>
                    Através dos nossos líderes locais são identificadas
                    comunidades vulneráveis, em que a própria população sinta
                    como necessidade este projeto.
                  </p>
                  <p>
                    O mesmo visa ajudar a combater a desnutrição de forma
                    sustentável e a longo prazo. Possibilitando no futuro, ser
                    uma fonte de gerar rendimento para as famílias e
                    comunidades.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-container">
        <div className="row">
          <div className="col">
            <div id="Description">
              <div className="case-b-text row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="project-cause">
                    <div className="cause-img">
                      <Image
                        src={projApoioDeslocadosCaboDelgadoImg}
                        webp={projApoioDeslocadosCaboDelgadoImgWebP}
                        alt="Projeto de Apoio aos Deslocados de Cabo Delgado"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0">
                  <h3>Projeto de Apoio aos Deslocados de Cabo Delgado</h3>
                  <p>
                    Este projeto presta apoio às mulheres solteiras/viúvas, que
                    tenham crianças e jovens a seu cargo. Estas pessoas
                    encontram-se em situação de grande vulnerabilidade, visto
                    serem deslocadas de Cabo Delgado.
                  </p>
                  <p>
                    Apoiamos na área em que têm um maior saber, na área da
                    agricultura, fornecendo Kits Agrícolas para que possam no
                    futuro serem independentes ao nível da nutrição e
                    possibilitando, gerar rendimento familiar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ProjectsPhotos photosList={photosList} />
    </div>
    <Footer />
    <Scrollbar />
  </>
);
export default ProjectSustentabilidadeAlimentarPage;
