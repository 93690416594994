import React from "react";
import Image from "react-image-webp";
import "./style.css";
import tm1 from "../../assets/images/team-01.jpg";
import tm1WebP from "../../assets/images/webp/team-01.webp";
import tm2 from "../../assets/images/team-02.jpg";
import tm2WebP from "../../assets/images/webp/team-02.webp";
import tm3 from "../../assets/images/team-03.jpg";
import tm3WebP from "../../assets/images/webp/team-03.webp";
import tm4 from "../../assets/images/team-04.jpg";
import tm4WebP from "../../assets/images/webp/team-04.webp";

const TeamSection = (props) => (
  <div className={`volunteer-area section-padding ${props.addclass}`}>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="section-title section-title2 text-center">
            <div className="thumb-text">
              <span>Missão</span>
            </div>
            <p>
              A <b>IMANI</b> é uma Associação Portuguesa que tem como missão,
              Promover e co-desenvolver oportunidades sustentáveis que se
              adequem às aspirações e necessidades de indivíduos e comunidades,
              que se encontrem em contextos de vulnerabilidade. Acreditando na
              intrínseca capacidade de cada pessoa em ser o ator principal do
              seu desenvolvimento.
            </p>
          </div>
        </div>
      </div>
      <div className="volunteer-wrap">
        <div className="row">
          <div className="col col-lg-3 col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <div className="volunteer-img">
                <Image
                  src={tm1}
                  webp={tm1WebP}
                  alt="foto equipa 1"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <div className="volunteer-img">
                <Image
                  src={tm2}
                  webp={tm2WebP}
                  alt="foto equipa 2"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <div className="volunteer-img">
                <Image
                  src={tm3}
                  webp={tm3WebP}
                  alt="foto equipa 3"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div className="col col-lg-3 col-md-6 custom-grid col-12">
            <div className="volunteer-item">
              <div className="volunteer-img">
                <Image
                  src={tm4}
                  webp={tm4WebP}
                  alt="foto equipa 4"
                  draggable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TeamSection;
