import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import ProjectsSection from "../components/Projects/Projects";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import "./Projects.css";

const EventPage = () => (
  <>
    <Navbar />
    <PageTitle
      title={"Projetos em Moçambique"}
      backgroundClassName={"projects"}
    />
    <ProjectsSection />
    <Footer />
    <Scrollbar />
  </>
);
export default EventPage;
