import React from "react";
import Image from "react-image-webp";
import featuresImg from "../../assets/images/features.jpeg";
import featuresImgWebP from "../../assets/images/webp/features.webp";
import "./style.css";

const Features = () => (
  <div className="features-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <div className="features-text">
            <h2>Os Nossos Valores</h2>
            <p>
              Justiça Social, Igualdade de Oportunidades, Empoderamento,
              Empatia, Equidade, Dignidade, Liderança pelo Serviço, Autonomia,
              Sustentabilidade e Liberdade
            </p>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="features-wrap">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="features-item-2 ">
                  <Image
                    src={featuresImg}
                    webp={featuresImgWebP}
                    alt="Saco IMANI"
                    draggable="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Features;
