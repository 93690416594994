import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import SolidatiryArticles from "../components/SolidatiryArticles";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

const SolidatiryArticlesPage = () => (
  <>
    <Navbar />
    <PageTitle backgroundClassName={"solitary"} title={"Artigos solidários"} />
    <SolidatiryArticles />
    <Footer />
    <Scrollbar />
  </>
);

export default SolidatiryArticlesPage;
