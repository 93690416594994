import React from "react";
import Image from "react-image-webp";
import "./style.css";

/**
 *
 * Associação Aquarela - https://web.facebook.com/profile.php?id=100079511740375
 * My Moyo - web.facebook.com/bymymoyo
 * Chiky Gentil - https://web.facebook.com/profile.php?id=100064532778669
 * YogaBraga - https://web.facebook.com/bragayoga
 * Compra solidária - https://www.comprasolidaria.pt
 */

const partnersList = [
  {
    logo: "partners-aquarela-adss.jpg",
    logoWebP:"partners-aquarela-adss.webp",
    name: "Associação Aquarela",
    website: "https://facebook.com/profile.php?id=100079511740375",
    descriptiom:
      "Associação Moçambicana sem fins lucrativos que ajuda comunidades vulneráveis de Nampula",
  },
  {
    logo: "partners-mym-yo.jpg",
    logoWebP: "partners-mym-yo.webp",
    name: "My Moyo",
    website: "https://facebook.com/profile.php?id=100079511740375",
    descriptiom:
      "Clube de Costura solidaria de apoio a causas sociais nacionais e internacionais",
  },
  {
    logo: "partners-gentil-chiky-patas.jpg",
    logoWebP: "partners-gentil-chiky-patas.webp",
    name: "Chiky Gentil",
    website: "https://facebook.com/profile.php?id=100064532778669",
    descriptiom: "Grupo de apoio informal a causas sociais e animal",
  },
  {
    logo: "partners-yoga-braga.jpg",
    logoWebP: "partners-yoga-braga.webp",
    name: "YogaBraga",
    website: "https://facebook.com/bragayoga",
    descriptiom: "Espaço de bem-estar",
  },
  {
    logo: "partners-compra-solidaria.jpg",
    logoWebP: "partners-compra-solidaria.webp",
    name: "Compra solidária",
    website: "https://www.comprasolidaria.pt",
    descriptiom:
      "Presentes solidários, serviços com impacto social positivo e eventos por uma causa",
  },
];

const Causes = () => (
  <div className="case-area section-padding">
    <div className="container">
      <div className="col-lg-8 offset-lg-2">
        <div className="section-title section-title2 text-center">
          <p>
            Sendo a IMANI uma instituição sem fins lucrativos, as parcerias são
            muito bem-vindas. Faça parte desta lista e ajude-nos a ajudar.
          </p>
        </div>
      </div>
      <div className="row">
        {partnersList &&
          partnersList.map((partner, index) => (
            <div className="col-lg-4 col-md-6 col-12" key={`partner-${index}`}>
              <div className="cause-item">
                <div className="cause-top">
                  <div className="cause-img">
                    <Image
                      src={require(`../../assets/images/${partner.logo}`)}
                      webp={require(`../../assets/images/webp/${partner.logoWebP}`)}
                      alt={partner.name}
                      draggable="false"
                    />
                  </div>
                </div>
                <div className="cause-text">
                  <h3>
                    <a
                      href={partner.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {partner.name}
                    </a>
                  </h3>
                  <p>{partner.descriptiom}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default Causes;
