import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Features from "../components/Features";
import TeamSection from "../components/team";
import Testimonial from "../components/Testimonial";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

const slideData = [
  {
    title: "Nelson Mandela",
    description:
      "A pobreza não é um acidente.<br/> É feita pelo homem e pode ser erradicada pelas acções dos seres humanos",
  },
];

const AboutPage = () => (
  <>
    <Navbar />
    <PageTitle backgroundClassName="about" title={"Quem somos"} />
    <TeamSection />
    <Features />
    <Testimonial slideData={slideData} noBackground />
    <Footer />
    <Scrollbar />
  </>
);

export default AboutPage;
